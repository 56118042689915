import { OSDashboardData } from './dashboard/os-dashboard/OSDashboard';
import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { get, webApi } from './utils/http';
import { useMemo } from 'react';
import { addMonths, format } from 'date-fns';
import { RocheDashboardData } from './dashboard/roche-dashboard/RocheDashboard';

export enum ENDPOINT {
    /* Dashboards */
    OS_DASHBOARD = 'api/os/dashboard/',
    ROCHE_DASHBOARD = 'api/dashboard/',
    ROCHE_ALL_OS = 'api/os/',
    /* Transactions */
    GET_ROCHE_MONITOR_TRANSACTIONS = 'api/registrosauditorias/',
}

export enum QUERY_KEY {
    /* Dashboards */
    OS_DASHBOARD = 'osDashboard',
    ROCHE_DASHBOARD = 'rocheDashboard',
    ROCHE_ALL_OS = 'rocheAllOS',
    /* Transactions */
    GET_ROCHE_MONITOR_TRANSACTIONS = 'GET_ROCHE_MONITOR_TRANSACTIONS',
}

interface DashboardQueryParams {
    defaultStartDate: string | null;
    defaultEndDate: string | null;
    startDate: string;
    endDate: string;
}

export const useOSDashboardQuery = (params: DashboardQueryParams) => {
    const queryKey = useMemo(() => [QUERY_KEY.OS_DASHBOARD, params], [params]);
    const startDate = params.startDate
        ? format(addMonths(new Date(params.startDate), 1), 'MMYYY')
        : params.defaultStartDate
        ? params.defaultStartDate
        : null;
    const endDate = params.endDate
        ? format(addMonths(new Date(params.endDate), 1), 'MMYYY')
        : params.defaultEndDate
        ? params.defaultEndDate
        : null;

    return useQuery<OSDashboardData, AxiosError>(
        queryKey,
        async () => {
            const response = await get(
                webApi + ENDPOINT.OS_DASHBOARD,
                false,
                params.startDate || params.endDate
                    ? {
                          desde: startDate || '',
                          hasta: endDate || '',
                      }
                    : undefined,
            );
            return response;
        },
        { refetchOnWindowFocus: false },
    );
};

interface RocheDashboardQueryParams extends DashboardQueryParams {
    selectedOS: string | null;
}

export const useRocheDashboardQuery = (params: RocheDashboardQueryParams) => {
    let newParams = {};
    const queryKey = useMemo(() => [QUERY_KEY.OS_DASHBOARD, params], [params]);
    const startDate = params.startDate
        ? format(addMonths(new Date(params.startDate), 1), 'MMYYY')
        : params.defaultStartDate
        ? params.defaultStartDate
        : null;
    const endDate = params.endDate
        ? format(addMonths(new Date(params.endDate), 1), 'MMYYY')
        : params.defaultEndDate
        ? params.defaultEndDate
        : null;
    if (params.startDate || params.endDate) {
        newParams = { ...newParams, desde: startDate || '', hasta: endDate || '' };
    }
    if (params.selectedOS) {
        newParams = { ...newParams, obra_social_id: parseInt(params.selectedOS) };
    }

    return useQuery<RocheDashboardData, AxiosError>(
        queryKey,
        async () => {
            const response = await get(
                webApi + ENDPOINT.ROCHE_DASHBOARD,
                false,
                Object.keys(newParams).length === 0 ? undefined : newParams,
            );
            return response;
        },
        { refetchOnWindowFocus: false },
    );
};

interface OSDataItem {
    activo: boolean;
    addresses: {
        activo: boolean;
        address: string;
        created_at: string;
        descripcion: string;
        granted: boolean;
        id: number;
        last_modified_by: number;
        obra_social: number;
        updated_at: string;
    };
    cod_cli_roche: string;
    created_at: string;
    cuit: string;
    direccion: string;
    email: string;
    email_recover_key: string;
    encryption_key: string;
    id: number;
    last_modified_by: number;
    migrated_nexus2: boolean;
    pago_directo: boolean;
    pubkey: string;
    pubkey_data: string;
    razon_social: string;
    recibir_notificaciones: boolean;
    sitio_web: string;
    telefon: string;
    tipo: string;
    updated_at: string;
}

export const useRocheGetAllOSQuery = () =>
    useQuery<OSDataItem[], AxiosError>(
        [QUERY_KEY.ROCHE_ALL_OS],
        async () => {
            const response = await get(webApi + ENDPOINT.ROCHE_ALL_OS);
            return response;
        },
        { refetchOnWindowFocus: false },
    );

/**
 * Transactions
 */
export interface MonitorTransaction {
    aplicacion: number;
    ciclo_actual: number;
    created_at: string;
    descripcion: string;
    estado: string;
    flia_producto: string;
    grupo_auditor: number;
    grupo_auditor_descr: string | null;
    id: number;
    instrucciones: string;
    last_modified_by: number;
    obra_social: {
        id: number;
        razon_social: string;
    };
    paciente: {
        activo: boolean;
        afiliado_enc: string;
        csee: string | null;
        dni_enc: string;
        fecha_nacimiento_enc: string;
        id: number;
        nombre_enc: string;
        paciente_id: number;
        peso: number;
        sexo: string;
    };
    paciente_contrato: number;
    paciente_contrato_activo: boolean;
    paciente_contrato_enckey_c: string;
    paciente_evidencia: number;
    producto_descr: string;
    registro_consumo: null;
    tipo: string;
    updated_at: string;
    audit_historial: {
        comentario_env: string;
        estado: string;
        id: number;
        usuario: string;
        usuario_auditor: number | null;
        usuario_drogueria: number | null;
        usuario_os: number | null;
    }[];
    contrato: {
        contrato_peso_req: boolean;
        descripcion: string;
        dosis_notificar: number;
        estado_version: string;
        evidencias_notificar: number;
        grupo_auditor_id: number;
        grupo_auditor_pubkey_data: string;
        habilitado: boolean;
        id: number;
        indicacion: number;
        indicacion_descr: string | null;
        obra_social: number;
        obra_social_descr: string | null;
        tipo_contrato: number;
        tipo_contrato_descr: string | null;
        tipo_contrato_tipo: string;
        version_actual: number;
    };
}

export const useRocheGetMonitorTransactions = () => {
    return useQuery<MonitorTransaction[], AxiosError>(
        [QUERY_KEY.GET_ROCHE_MONITOR_TRANSACTIONS],
        async () => {
            return await get(webApi + ENDPOINT.GET_ROCHE_MONITOR_TRANSACTIONS);
        },
        { refetchOnWindowFocus: false, staleTime: 1000 * 60 * 60 /* 1 hour */, retry: 0 },
    );
};
